import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import { graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "drive"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/drive.jpg"}) { ...eyecatchImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`DVD,BDドライブは必要か？内蔵か外付けどちらか？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="BD/DVDドライブって必要？" mdxType="Image" />
    <p>{`インターネットで動画を見るストリーミングの時代に、DVD/BDドライブなど光学ドライブをパソコンに搭載するメリットはあるのか、外付けか内蔵かどちらが良いかなどを様々な視点から解説する。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "ストリーミング配信の時代DVDを借りてきて見るか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B9%E3%83%88%E3%83%AA%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0%E9%85%8D%E4%BF%A1%E3%81%AE%E6%99%82%E4%BB%A3DVD%E3%82%92%E5%80%9F%E3%82%8A%E3%81%A6%E3%81%8D%E3%81%A6%E8%A6%8B%E3%82%8B%E3%81%8B%EF%BC%9F",
        "aria-label": "ストリーミング配信の時代DVDを借りてきて見るか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ストリーミング配信の時代DVDを借りてきて見るか？`}</h2>
    <p>{`DVDやBDはHuluやネットフリックス、Amazon Prime Videoのようなネット配信サービスが多い今、DVDを見るだろうか？それほど見ないのではないか？`}</p>
    <p>{`少なくともあるコンテンツをみたいと思えば、レンタルビデオショップ以外にも様々な選択肢があるため`}<strong parentName="p">{`コンテンツを視聴する目的でBD/DVDドライブをつけることは必須ではなくなった`}</strong>{`といえる。`}</p>
    <p>{`ただ、TSUTAYAなどのDVD/BDレンタル料金はストリーミングより安い場合も多い。特にネット配信サービスはいずれも定額制のサービスとなっており利用頻度に関わらず月額で料金が掛かるため、利用状況によっては単発のレンタルよりも不利となる。`}</p>
    <p>{`また、単発のレンタルをネット配信サービスで行うこともできるが、料金が高い場合が多い。レンタルビデオショップならば100円で借りられるような旧作がネットでは400~500円掛かる場合が大抵である。`}</p>
    <p>{`コストを重視するという場合でかつ、BDプレーヤーが家に無い場合はあった方が良い場合もある。`}</p>
    <h2 {...{
      "id": "ブルーレイに焼くよりはハードディスクに保存？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%96%E3%83%AB%E3%83%BC%E3%83%AC%E3%82%A4%E3%81%AB%E7%84%BC%E3%81%8F%E3%82%88%E3%82%8A%E3%81%AF%E3%83%8F%E3%83%BC%E3%83%89%E3%83%87%E3%82%A3%E3%82%B9%E3%82%AF%E3%81%AB%E4%BF%9D%E5%AD%98%EF%BC%9F",
        "aria-label": "ブルーレイに焼くよりはハードディスクに保存？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ブルーレイに焼くよりはハードディスクに保存？`}</h2>
    <p>{`またデータ保存用の書き込み需要についてはどうだろうか？DVDに焼いて動画を保存するよりは、`}<strong parentName="p">{`ハードディスクにでも保存した方がずっと安くつく`}</strong>{`。よって録画目的でもあまり必要ではなさそうである。`}</p>
    <p>{`反論として、長期間のデータ保存という意味では、光学ディスクメディアは10年以上余裕で保存ができ、ハードディスクよりも優れているといえる。`}</p>
    <p>{`しかし、GoogleドライブやマイクロソフトのOneドライブなどのクラウドストレージを用いればより長期間のデータ保存が可能であり、CD/DVDは中途半端な感がある。`}</p>
    <h2 {...{
      "id": "やはりたまに必要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%84%E3%81%AF%E3%82%8A%E3%81%9F%E3%81%BE%E3%81%AB%E5%BF%85%E8%A6%81",
        "aria-label": "やはりたまに必要 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`やはりたまに必要`}</h2>
    <p>{`映像はストリーミングで事足りるし、データ保存にも中途半端。では全く必要がないか？と言われるとそうでもない。市販のソフトを購入するときは取り込みに必要であるし、書籍の付録としてCDROMがついてくることはまだまだ多い。音楽CDの取り込みでも必要となってくる。`}</p>
    <p>{`また結婚式のムービー作りでDVDが必要だったり、写真の共有でUSBの手渡しだとというわけにはいかず、DVDに焼いたりとDVDの書き込みが必要となることも`}</p>
    <p>{`よって`}<strong parentName="p">{`一家に一つはあって然るべき`}</strong>{`だと思う。これらの場合、BDまで必要になるケースはそうはないと思うのでDVDまでで十分である。また、`}<a parentName="p" {...{
        "href": "https://amzn.to/37QUTvM",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`外付けDVDドライブ`}</a>{`もかなり安く販売されているので、必要に応じて外付けで対応するでも良いと思われる。`}</p>
    <h2 {...{
      "id": "海外版DVDをパソコンで見られる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%B5%B7%E5%A4%96%E7%89%88DVD%E3%82%92%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%A7%E8%A6%8B%E3%82%89%E3%82%8C%E3%82%8B",
        "aria-label": "海外版DVDをパソコンで見られる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`海外版DVDをパソコンで見られる`}</h2>
    <p>{`他の有用なケースもある。北米版のDVDを日本のDVDプレイヤーで見ようとすると、地域が対応しておりませんとエラーが表示され、視聴することができない。DVDにはリージョンコードという地域ごとに視聴を制限する機能が転売や海賊版対策の観点から盛り込まれているからである。`}</p>
    <p>{`しかし、`}<strong parentName="p">{`パソコンのフリーソフトを使えば簡単に視聴することができてしまう`}</strong>{`。英語学習用コンテンツ、海外ドラマ、日本で買うと高いアニメのDVDなどを購入して見ることができ、これはDVDをつけて得をしたと思ったことである。`}</p>
    <p>{`ちなみに`}<strong parentName="p">{`BDは北米と日本のリージョンコードが何故か同じとなっており、自由に見ることができる`}</strong>{`。おそらく日本語字幕が北米版には入っておらず、リージョンが等しくとも害がないと判断されたのだろう。`}</p>
    <h2 {...{
      "id": "まとめ：BDDVDは必要か",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81%EF%BC%9ABDDVD%E3%81%AF%E5%BF%85%E8%A6%81%E3%81%8B",
        "aria-label": "まとめ：BDDVDは必要か permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ：BD/DVDは必要か?`}</h2>
    <p>{`まとめると、ストリーミング時代に映像再生機としての役割は小さく、BD/DVDソフトレンタルで安く済ましたい、ネットに繋がらない環境での映像視聴を行う場合を除いてはBD/DVDドライブは必須とはならない。`}</p>
    <p>{`しかし音楽CDの取り込み、ムービーの作成などどうしても必要なケースが稀にあるのでやはり一つは最低欲しいところである。北米版DVD視聴ができることも追加メリットである。`}</p>
    <p>{`BDかDVDどちらが良いかに関しては、より高画質で映像コンテンツを楽しみたい場合でコスト的に問題が無ければBDを選択すればよい。`}</p>
    <p>{`ちなみに私は主にストリーミングで映像を視聴し、BDは実家に帰省した時に親がとりためている番組で面白いものがあれば焼いてもらい、稀に見ることがある程度。プレステでBDを見るためデスクトップパソコンにはBDではなくDVDドライブを搭載している。`}</p>
    <h2 {...{
      "id": "外付けか内蔵どちらが良いか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%A4%96%E4%BB%98%E3%81%91%E3%81%8B%E5%86%85%E8%94%B5%E3%81%A9%E3%81%A1%E3%82%89%E3%81%8C%E8%89%AF%E3%81%84%E3%81%8B%EF%BC%9F",
        "aria-label": "外付けか内蔵どちらが良いか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`外付けか内蔵どちらが良いか？`}</h2>
    <p><strong parentName="p">{`ノートパソコンならば外付け`}</strong>{`、`}<strong parentName="p">{`デスクトップパソコンならば内蔵`}</strong>{`が大抵良いと思うが、その理由を説明する。`}</p>
    <h3 {...{
      "id": "ノートパソコンならば外付けが良い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AA%E3%82%89%E3%81%B0%E5%A4%96%E4%BB%98%E3%81%91%E3%81%8C%E8%89%AF%E3%81%84",
        "aria-label": "ノートパソコンならば外付けが良い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコンならば外付けが良い`}</h3>
    <p>{`ノートパソコンにDVD/BDドライブが搭載された場合、筐体は厚みを増す。この結果`}<strong parentName="p">{`重量が増え、持ち運びに不便`}</strong>{`になるばかりか、`}<strong parentName="p">{`厚みのせいでややキーボードが押しにくい`}</strong>{`。`}</p>
    <p>{`使用頻度としてはどうだろうか？映像はインターネットに接続して視聴し、たまにCDの取り込みで使うという一般的なケースを想定すると、使用頻度は決して高くはならない。`}</p>
    <p>{`滅多に使わないものを毎日持ち運ぶのは無駄が多いのでノートパソコンは外付けのBD/DVDドライブで対応するのがおすすめである。`}</p>
    <h3 {...{
      "id": "デスクトップならば内蔵",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%AA%E3%82%89%E3%81%B0%E5%86%85%E8%94%B5",
        "aria-label": "デスクトップならば内蔵 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デスクトップならば内蔵`}</h3>
    <p>{`持ち運ぶ必要のないデスクトップならば、内蔵されているデメリットは無い。また外付けプレイヤーをUSBで挿して、終わったら片付けるという手間も掛からない。`}</p>
    <p>{`また、内蔵用のドライブと外付け用のドライブを比較すると、一般的に`}<strong parentName="p">{`内蔵用の方が書き込みが速く安価である`}</strong>{`。`}</p>
    <p>{`パソコンの買替え時にドライブを再利用を考えた場合、筐体を開けてドライブを取り出さなければならず、外付けと比べ面倒であるがデメリットはその程度である。`}</p>
    <p>{`よってデスクトップならば内蔵のドライブがおすすめである。`}</p>
    <h2 {...{
      "id": "分かりにくい読み書き対応とドライブ名称",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%88%86%E3%81%8B%E3%82%8A%E3%81%AB%E3%81%8F%E3%81%84%E8%AA%AD%E3%81%BF%E6%9B%B8%E3%81%8D%E5%AF%BE%E5%BF%9C%E3%81%A8%E3%83%89%E3%83%A9%E3%82%A4%E3%83%96%E5%90%8D%E7%A7%B0",
        "aria-label": "分かりにくい読み書き対応とドライブ名称 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`分かりにくい読み書き対応とドライブ名称`}</h2>
    <p>{`DVD,BDコンボドライブとは何か？機能で迷うとしたらここであろう。`}</p>
    <p>{`DVDコンボドライブとはCDは書き込み、読み込みができ、`}<strong parentName="p">{`DVDの書き込みはできないが読み込みはできる`}</strong>{`というものである。ただしBDへの読み書きはできない。`}</p>
    <p>{`DVDスーパーマルチドライブならば`}<strong parentName="p">{`DVD、CDへの書き込み、読み込みが両方できる`}</strong>{`。`}</p>
    <p>{`BDコンボドライブとは`}<strong parentName="p">{`BDに書き込みはできないが、BD読み込みができる`}</strong>{`、即ちブルーレイディスクドライブの再生ができるというものである。また`}<strong parentName="p">{`BDコンボドライブはDVD,CDへの書き込み、読み込みが可能`}</strong>{`である。`}</p>
    <p>{`なお、ブルーレイディスクドライブならば`}<strong parentName="p">{`BDの書き込みも可能`}</strong>{`だ。`}</p>
    <p>{`まとめると次の図になる。CDの読み書きはいずれも可能だ。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <thead>
    <tr>
      <th></th>
      <th>DVD読み込み</th>
      <th>DVD書き込み</th>
      <th>BD読み込み</th>
      <th>BD書き込み</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>DVDコンボドライブ</td>
      <td>◯</td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>DVD(スーパー)マルチドライブ</td>
      <td>◯</td>
      <td>◯</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>BDコンボドライブ</td>
      <td>◯</td>
      <td>◯</td>
      <td>◯</td>
      <td></td>
    </tr>
    <tr>
      <td>BDディスクドライブ</td>
      <td>◯</td>
      <td>◯</td>
      <td>◯</td>
      <td>◯</td>
    </tr>
  </tbody>
    </BasicTable>
    <h2 {...{
      "id": "コンボドライブ、スーパーマルチドライブの名前表記",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B3%E3%83%B3%E3%83%9C%E3%83%89%E3%83%A9%E3%82%A4%E3%83%96%E3%80%81%E3%82%B9%E3%83%BC%E3%83%91%E3%83%BC%E3%83%9E%E3%83%AB%E3%83%81%E3%83%89%E3%83%A9%E3%82%A4%E3%83%96%E3%81%AE%E5%90%8D%E5%89%8D%E8%A1%A8%E8%A8%98",
        "aria-label": "コンボドライブ、スーパーマルチドライブの名前表記 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`コンボドライブ、スーパーマルチドライブの名前表記`}</h2>
    <p>{`コンボドライブはCD-R/RW with DVD-ROMコンボドライブ、CD-RW/DVD-ROM一体型といった様々な表記があるので注意しましょう。またスーパーマルチドライブはDVD-+RWなどと表記されていることがあるので注意しよう。`}</p>
    <p>{`取りあえず細かいところは気にせず、`}<strong parentName="p">{`コンボと付いたら書き込みができないんだなぁ、スーパーマルチならば全て備わっている（DVDスーパーマルチで読み書きできないDVD,CDはない）んだなぁ`}</strong>{`ぐらいで見ておいて良いと思います。ここの仕様は細かすぎてパソコン購入時には手に負えないので。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      